function PrivateRoute({
	component: Component,
	path,
	...props
}) {
	const {
		authData,
		setAuthData,
		route,
	} = props;
	const isLoggedIn = !!authData?.signInUserSession?.idToken;
	if (!isLoggedIn && path !== '/sign-in') {
		route('/sign-in');
		return null;
	}
	return (
		<Component
			{...props}
		/>
	);
}

export default PrivateRoute;
