import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Router, route } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import IonDisplay from '../routes/ionDisplay';
import ChangePassword from '../routes/changePassword';
import SignIn from '../routes/signIn';

import PrivateRoute from './privateRoute';

function App() {
	const [authData, setAuthData] = useState();
	return (
		<div id="app">
			<Router>
				<PrivateRoute
					path="/"
					authData={authData}
					route={route}
					setAuthData={setAuthData}
					component={Home}
				/>
				<PrivateRoute
					path="/ion-display"
					authData={authData}
					route={route}
					setAuthData={setAuthData}
					component={IonDisplay}
				/>
				<ChangePassword
					path="/change-password"
					authData={authData}
					route={route}
					setAuthData={setAuthData}
				/>
				<SignIn
					path="/sign-in"
					authData={authData}
					route={route}
					setAuthData={setAuthData}
				/>
			</Router>
		</div>
	);
}

export default App;
